.App {
 
}

.heropara {
  font-size: 14px;
  max-width: 40%;
  margin-top: 20px;
  color:white;
}

.hero {
  border-radius: 30px;
  background-color: #124B4F;
  border: none;
  color:white;
}

.herobg {
  background-image: url("http://localhost:3000/static/media/heroo.720f4edcf7c95ec12d94.png");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: right center;
}

.typevalue{
  color:#124B4F;
  margin-left: 15px;
  font-weight: 600;
}

.small {
  font-size: 13px;
  color:#D07750;
}


.appointment-calendar {
  border: none !important;
  border-radius: 8px !important;
  box-shadow: 0px 20px 100px rgba(10, 10, 10, 0.09);
  font-family: var(--font-body) !important;
  width: 100% !important;
  margin-bottom: 10px;
}

.react-calendar__month-view {
  padding: 10px !important;
}

.react-calendar__tile--active {
  background: transparent !important;
  /* background: #27AE60 !important;
  border-radius: 50%;
  height: 48px; */
}

.react-calendar__tile--active abbr {
  background: #27ae60 !important;
}

.react-calendar__tile {
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-calendar__tile abbr {
  display: flex;
  width: 46px;
  height: 46px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
}

/* element.style {
  display: flex;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  background-color: yellow;
  border-radius: 50px;
} */

.react-calendar__tile--now {
  background: transparent !important;
}

.react-calendar__tile--now abbr {
  background: #27ae5f2a;
}

.react-calendar__navigation {
  height: 55px !important;
  background-color: #def2fb;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.react-calendar__navigation__label__labelText {
  display: inline-block;
  font-weight: 700;
  color: var(--color-secondary);
}

/* .react-calendar__navigation__label__labelText::before {
  content: " ";
  margin-right: 0.6rem;
  display: inline-block;
  position: relative;
  background: url(./../images/icons8-google-calendar.svg) no-repeat center
    center;
  width: 18px;
  height: 18px;
  top: 3px;
} */

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: transparent !important;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: transparent !important;
}

.react-calendar__tile:enabled:hover abbr,
.react-calendar__tile:enabled:focus abbr {
  background: #f1f1f185;
}

.react-calendar__navigation button[disabled],
.react-calendar__tile:disabled {
  background-color: transparent !important;
}


/* time picker */


.time-picker-container {
  margin-bottom: 0.7em;
  padding-bottom: 0.7em;
  border-bottom: var(--border-dashed);
}

.appointment-box {
  padding-bottom: 1em;
}

.appointment-box .col-md-6:last-child {
  padding-left: 5.5em;
}

.time-picker {
  border-radius: 8px;
  border: solid 1.5px #124B4F;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #124B4F;
  padding: 5px 0;
  font-weight: 600;
  font-size: 13px;
  cursor: pointer;
  margin-bottom: 18px;
}

.time-picker.active {
  background-color: #124B4F;
  color: #fff;
}



/* Summary */
.imgcenter{
  text-align: left;
}

.summaryimage{
  width: 70%;
  text-align: left;
}
.summaryimage2{
  width: 80%;
  
}
.summarypara{
  font-size:14px;
  font-weight: 500;
  line-height: 1.3;
}
.summaryheading{
  color:#D07750;
}

.download {
  background-color: #D07750;
  font-size: 18px;
  color:white;
}


.download2 {
  background-color: #124B4F;
  font-size: 18px;
  color:white;
}

.next {
  background:#D07750;
  color:white;
  border-radius:30px;
  width:40%;
  font-size:18px;
  margin:auto;
  font-weight:600;
}
 /* Tablet */

 @media screen and (min-width:765px) and (max-width:1023px) {
 
}


/* End Tablet */


/* Mobile Screen  */

@media screen and (min-width:300px) and (max-width:764px) {

  /* .heropara {
    font-size: 14px;
    max-width: 40%;
    margin-top: 20px;
    color:white;
  } */
  
  .heropara {
    font-size: 14px;
    max-width: 100%;
    margin-top: 20px;
    color:white;
    text-align: left;
  }
  
  .hero {
    width: 100%;
    background-color: #124B4F;
    border: none;
    color:white;
    border-radius: none;
    text-align: center;
  }
  
  .herobg {
    background-color: #124B4F;
    background-image: none;
  }

  .heroheading{
    font-size:19px;
  }

  .timediv {
    margin-top: 50px;
  }

  .next2{
    width:100%;
    font-size:18px;
    margin-top:20px;
  }
  .next {
    background:#D07750;
    color:white;
    border-radius:30px;
    width:100%;
    font-size:15px;
    margin:auto;
    font-weight:400;
  }
  .summarypara{
    font-size:14px;
    font-weight: 500;
    line-height: 1.3;
    text-align: center;
  }
  .summaryheading{
    color:#D07750;
    text-align: center;
    margin-bottom: 20px;
  }

  .imgcenter{
    text-align: center;
  }

  .summaryimage{
    width: 60%;
    text-align: center;
  }
}


/* End Mobile */